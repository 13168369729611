.button {
    color: #fff;
    background-color: #41bb7e !important;
    border-color: #41bb7e !important;
    float: right;
    margin-left: 20px;
}

.buttons {
    color: #fff;
    border-color: #41bb7e !important;
    float: right;

}
