.reg-btn {
    margin:20px;
}

.button {
    color: #fff;
    background-color: #41bb7e !important;
    border-color: #41bb7e !important;
    height: 40px;
    margin-left: 5px;
}

.signout_button {
    color: #fff !important;
    background-color: #41bb7e !important;
    border-color: #41bb7e !important;
    margin: 10px;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    top: 5%;
    right: 1%;
    position: absolute;
}

.form {
    float: right;
    position: absolute;
    right: 1%;
    top: 1%;
}

.danger {
    float: left;
    margin-top: 10px;
    width: 100%;
}

.button__reg {
    color: #fff;
    background-color: #41bb7e !important;
    border-color: #41bb7e !important;
    float: right;
    top: 5%;
    right: 1%;
    position: absolute;
}
.login {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
  
}

.label {
    color: white;
}
