.form {
    float: right;
    position: relative;
    right: 1%;
    top: 1%;
    color: #fff;
    background-color: #41bb7e !important;
    border-color: #41bb7e !important;
    height: 40px;
    margin-left: 5px;
}