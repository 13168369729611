.search {
  text-align: center;
}

.search__option {
  width: 150px !important;
  height: 38px;
}

.search__form {
  display: flex;
}

.search__value {
  width: 100% !important;
}

.search__button {
  background-color: #41bb7e !important;
  border-color: #41bb7e !important;
}