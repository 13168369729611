.navigation {
    margin: 10px 0 0 20px;
  }

.navigation__item:hover {
    color: #9ebeae;
}

.navigation__item.active {
    color: #41bb7e;
}

.navigation__item {
    color:  white;
    margin: 10px 0 20px 20px;
    text-decoration: none; 
    font: 600 24px Arial, Helvetica, sans-serif;
}

