.header {
  justify-content: space-between;
  grid-area: header;
  padding: 10px;
  background-color: black;
  margin-bottom: 20px;
  }

.header__img {
  width: 200px;
  }

  .header__greetings {
    font-size: 24px;
    color: white;
    text-align: center;
  }
