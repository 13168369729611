.button {
    color: #fff;
    background-color: #41bb7e !important;
    border-color: #41bb7e !important;
    margin: 10px;
}

.delete_button {
    color: #fff !important;
    background-color: #41bb7e !important;
    border-color: #41bb7e !important;
    margin: 10px;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
}